












































































.el-tab-pane {
  >div {
    height: 100%;
    position: relative;
  }
}
