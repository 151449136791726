.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
 /deep/ .el-switch__label--left.is-active + .el-switch__core {
  border-color: #ff4949 !important;
  background-color: #ff4949 !important;
}
 /deep/ .el-switch.is-checked .el-switch__core {
  background-color: #5fb878 !important;
  border-color: #5fb878 !important;
}
 /deep/ .el-switch__label--left {
  left: 0 !important;
  z-index: 999 !important;
}
 /deep/ .el-switch__label--right {
  right: 0 !important;
  z-index: 999 !important;
}
 /deep/ .el-switch__label {
  color: #666 !important;
}
 /deep/ .el-switch__label.is-active {
  color: #409eff !important;
}
.el-textarea {
  resize: none;
}
.el-textarea .el-textarea__inner {
  height: 200px;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.listWrap .el-tabs .el-tabs__content .el-tab-pane {
  height: 100%;
}
.Batch {
  padding: 1rem 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.Batch .BatchBox {
  display: flex;
  height: 70px;
  border: 1px solid #ebeef5;
  border-radius: 5px;
}
.Batch .BatchBox .BatchBoxFirst {
  width: 260px;
  background: #f2f7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Batch .BatchBox .BatchBoxSecond {
  width: 100%;
}
.Batch .BatchBox .BatchBoxSecond .BatchforBox {
  display: flex;
  flex-wrap: wrap;
}
.Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex .BatchImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Batch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex img {
  width: 16px;
  height: 16px;
}
